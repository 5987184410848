
import { defineComponent, nextTick, onMounted, reactive } from "vue";

export interface Data {
  errors: string[];
  isLoading: boolean;
  uploadReady: boolean;
  file: {
    name: string;
    size: number;
    type: string;
    fileExtention: string;
    url: any;
    blob?: any;
    isImage: boolean;
    isUploaded: boolean;
  };
}

export default defineComponent({
  name: "UnappliedCredit",
  components: {},
  props: ["defaultImg", "maxSize", "accept", "disabled"],
  setup(props, ctx) {
    let data = reactive<Data>({
      errors: [],
      isLoading: false,
      uploadReady: true,
      file: {
        name: "",
        size: 0,
        type: "",
        fileExtention: "",
        url: "",
        isImage: false,
        isUploaded: false,
      },
    });

    onMounted(async () => {
      if (props.defaultImg) {
        //TODO
        data.file.url = "data:image/png;base64," + props.defaultImg;
        data.file.isImage = true;
        data.file.isUploaded = true;
        data.file.blob = dataURLtoFile(data.file.url, "logo.png");
        sendDataToParent();
      }
      return;
    });

    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    function handleFileChange(e) {
      data.errors = [];
      // Check if file is selected
      if (e.target.files && e.target.files[0]) {
        // Check if file is valid
        if (isFileValid(e.target.files[0])) {
          // Get uploaded file
          const file = e.target.files[0],
            // Get file size
            fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100,
            // Get file extention
            fileExtention = file.name.split(".").pop(),
            // Get file name
            fileName = file.name.split(".").shift(),
            // Check if file is an image
            isImage = ["jpg", "jpeg", "png", "gif"].includes(fileExtention);
          // Print to console
          console.log(fileSize, fileExtention, fileName, isImage);
          // Load the FileReader API
          let reader = new FileReader();
          reader.addEventListener(
            "load",
            () => {
              // Set file data
              data.file = {
                name: fileName,
                size: fileSize,
                type: file.type,
                fileExtention: fileExtention,
                isImage: isImage,
                url: reader.result,
                blob: file,
                isUploaded: true,
              };
              sendDataToParent();
            },
            false
          );
          // Read uploaded file
          reader.readAsDataURL(file);
        } else {
          console.log("Invalid file");
        }
      }
    }

    function isFileSizeValid(fileSize) {
      if (fileSize <= props.maxSize) {
        console.log("File size is valid");
      } else {
        data.errors.push(`File size should be less than ${props.maxSize} MB`);
      }
    }

    function isFileTypeValid(fileExtention) {
      if (props.accept.split(",").includes(fileExtention)) {
        console.log("File type is valid");
      } else {
        data.errors.push(`File type should be ${props.accept}`);
      }
    }

    function isFileValid(file) {
      isFileSizeValid(Math.round((file.size / 1024 / 1024) * 100) / 100);
      isFileTypeValid(file.name.split(".").pop());
      if (data.errors.length === 0) {
        return true;
      } else {
        return false;
      }
    }

    function resetFileInput() {
      data.uploadReady = false;
      nextTick(() => {
        data.uploadReady = true;
        data.file = {
          name: "",
          size: 0,
          type: "",
          fileExtention: "",
          url: "",
          isImage: false,
          isUploaded: false,
        };
      });
    }

    function sendDataToParent() {
      ctx.emit("file-uploaded", data.file);
    }

    function remove() {
      data.file.url = null;
      data.file.isImage = false;
      data.file.isUploaded = false;
      data.file.blob = null;

      ctx.emit("file-uploaded", data.file);
    }

    return {
      data,
      handleFileChange,
      isFileSizeValid,
      isFileTypeValid,
      isFileValid,
      resetFileInput,
      sendDataToParent,
      remove,
    };
  },
});
